import React, {useEffect} from "react"
import Header from "../../components/header"
import { FaTrophy } from 'react-icons/fa'
import teamsData from "../../../static/data/teams.json"
import { Chart } from 'chart.js'
import {Helmet} from "react-helmet"

function Page({teams}) {
  useEffect(() => {
    const teams = teamsData;
    const season = "2018";

    let teamNames = [];
    let teamColors = [];
    let teamPFs = [];
    let teamPAs = [];
    let teamAvgScores = [];
    let teamAboveAvgPercs = [];
    let teamTrans = [];
    let teamTier1QBs = [];
    let teamTier1RBs = [];
    let teamTier1WRs = [];
    let teamTier1TEs = [];
    let teamTier1DSTs = [];
    let teamTier1Ks = [];

    for (var i = 0; i < teams.length; i++) {
      const teamSeasonData = teams[i].seasons[season];

      teamNames.push(teamSeasonData.name);
      teamColors.push(teams[i].color);
      teamPFs.push(teamSeasonData.pointsFor);
      teamPAs.push(teamSeasonData.pointsAgainst);
      teamAvgScores.push(teamSeasonData.avgScore);
      teamAboveAvgPercs.push(teamSeasonData.aboveAvgPerc);
      teamTrans.push(teamSeasonData.transactions);

      teamTier1QBs.push(teamSeasonData.tier1_QB.length);
      teamTier1RBs.push(teamSeasonData.tier1_RB.length);
      teamTier1WRs.push(teamSeasonData.tier1_WR.length);
      teamTier1TEs.push(teamSeasonData.tier1_TE.length);
      teamTier1DSTs.push(teamSeasonData.tier1_DST.length);
      teamTier1Ks.push(teamSeasonData.tier1_K.length);
    }

    new Chart(document.getElementById("chartPF"), {
      type: 'horizontalBar',
      data: {
        datasets: [{
          data: teamPFs,
          backgroundColor: teamColors,
        }],

        labels: teamNames
      },
      options: {
        legend: {
          display: false
        }
      }
    });

    new Chart(document.getElementById("chartPA"), {
      type: 'horizontalBar',
      data: {
        datasets: [{
          data: teamPAs,
          backgroundColor: teamColors,
        }],

        labels: teamNames
      },
      options: {
        legend: {
          display: false
        }
      }
    });

    new Chart(document.getElementById("chartAvgScore"), {
      type: 'horizontalBar',
      data: {
        datasets: [{
          data: teamAvgScores,
          backgroundColor: teamColors,
        }],

        labels: teamNames
      },
      options: {
        legend: {
          display: false
        }
      }
    });

    // doesn't pull from array
    new Chart(document.getElementById("chartHighestScore"), {
      type: 'horizontalBar',
      data: {
        datasets: [{
          data: [
            '192.6',
            '184.3',
            '183.9',
            '181.8',
            '179.8',
            '173.2',
            '173.1',
            '163.7',
            '163.2',
            '162.1'
          ],
          backgroundColor: [
            teamColors[4],
            teamColors[9],
            teamColors[6],
            teamColors[0],
            teamColors[1],
            teamColors[4],
            teamColors[7],
            teamColors[9],
            teamColors[7],
            teamColors[6]
          ],
        }],
        labels: [
          teamNames[4] + ' - Week 10',
          teamNames[9] + ' - Week 8',
          teamNames[6] + ' - Week 4',
          teamNames[0] + ' - Week 11',
          teamNames[1] + ' - Week 5',
          teamNames[4] + ' - Week 8',
          teamNames[7] + ' - Week 13',
          teamNames[9] + ' - Week 12',
          teamNames[7] + ' - Week 2',
          teamNames[6] + ' - Week 7'
        ]
      },
      options: {
        legend: {
          display: false
        }
      }
    });

    new Chart(document.getElementById("chartAboveAvgPerc"), {
      type: 'polarArea',
      data: {
        datasets: [{
          data: teamAboveAvgPercs,
          backgroundColor: teamColors,
        }],

        labels: teamNames
      },
      options: {
        legend: {
          display: true,
          position: 'left'
        },
        responsive: true,
      }
    });

    new Chart(document.getElementById("chartTrans"), {
      type: 'doughnut',
      data: {
        datasets: [{
          data: teamTrans,
          backgroundColor: teamColors,
        }],

        labels: teamNames
      },
      options: {
        legend: {
          display: true,
          position: 'left'
        },
        responsive: true
      }
    });

    new Chart(document.getElementById("chartTier1"),{
      type:"horizontalBar",
      data:{
        labels: teamNames,
        datasets:[
          {
            label: "QB",
            data: teamTier1QBs,
            backgroundColor: "#C62828"
          },
          {
            label: "RB",
            data: teamTier1RBs,
            backgroundColor: "#2E7D32"
          },
          {
            label: "WR",
            data: teamTier1WRs,
            backgroundColor: "#1565C0"
          },
          {
            label: "TE",
            data: teamTier1TEs,
            backgroundColor: "#F9A825"
          },
          {
            label: "D/ST",
            data: teamTier1DSTs,
            backgroundColor: "#6A1B9A"
          },
          {
            label: "K",
            data: teamTier1Ks,
            backgroundColor: "#4E342E"
          }
        ]
      },
      options: {
        scales: {
          xAxes: [{
              stacked: true
          }],
          yAxes: [{
              stacked: true
          }]
        }
      }
    });
  }, [teams]) 

  return (
    <React.Fragment>
      <Helmet>
        <title>2018 Season Recap | Chode Dynasty</title>
      </Helmet>
      
      <Header />
      <main>
        <div className="row">
          <div className="inner">
            <h1>2018 Season Recap</h1>
            
            <h2>Final Standings</h2>
            <ol className="standings">
              <li>
                <span className="label">1<sup>st</sup></span> Sportsball bois (9-4)
                <div className="trophy place-1"><FaTrophy /></div>
              </li>
              
              <li>
                <span className="label">2<sup>nd</sup></span> Piss Jugs (9-4)
                <div className="trophy place-2"><FaTrophy /></div>
              </li>
              
              <li>
                <span className="label">3<sup>rd</sup></span> Dumpster Fire (9-4)
                <div className="trophy place-3"><FaTrophy /></div>
              </li>
              
              <li>
                <span className="label">4<sup>th</sup></span> ROMOLICIOUS 9 (9-4)
              </li>
              
              <li>
                <span className="label">5<sup>th</sup></span> FART JUGS (8-5)
              </li>
              
              <li>
                <span className="label">6<sup>th</sup></span> Cookie Boi (6-7)
              </li>
              
              <li>
                <span className="label">7<sup>th</sup></span> RAVENous Pickle (6-7)
              </li>
              
              <li>
                <span className="label">8<sup>th</sup></span> Team Cory (6-7)
              </li>
              
              <li>
                <span className="label">9<sup>th</sup></span> Jeffy Sessions (3-10)
              </li>
              
              <li>
                <span className="label">10<sup>th</sup></span> Team Frank (0-13)
              </li>
            </ol>
          </div>
        </div>

        <div className="row">
          <div className="inner">
            <h2>Total Points For</h2>
            <canvas id="chartPF"></canvas>
          </div>
        </div>

        <div className="row">
          <div className="inner">
            <h2>Total Points Against</h2>
            <canvas id="chartPA"></canvas>
          </div>
        </div>

        <div className="row">
          <div className="inner">
            <h2>Team Average Score</h2>
            <canvas id="chartAvgScore"></canvas>
          </div>
        </div>

        <div className="row">
          <div className="inner">
            <h2>% of Games with Above Average Score</h2>
            <canvas id="chartAboveAvgPerc"></canvas>
          </div>
        </div>

        <div className="row">
          <div className="inner">
            <h2>Highest Scores</h2>
            <canvas id="chartHighestScore"></canvas>
          </div>
        </div>

        <div className="row">
          <div className="inner">
            <h2>Player Acquisitions</h2>
            <canvas id="chartTrans"></canvas>
          </div>
        </div>

        <div className="row">
          <div className="inner">
            <h2>Top Scoring Players by Position</h2>
            
            <div className="cols-2">
              <div className="top-score-pos">
                <h3>Quarterback</h3>
                <table className="styled">
                  <thead>
                    <tr>
                      <th>Rank</th>
                      <th>Player</th>
                      <th>Total Points</th>
                      <th>Manager</th>
                    </tr>
                  </thead>
        
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>P. Mahomes</td>
                      <td>495.7</td>
                      <td>Sportsball bois</td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>B. Roethlisberger</td>
                      <td>393.6</td>
                      <td>Team Cory</td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>M. Ryan</td>
                      <td>391.6</td>
                      <td>RAVENous Pickle</td>
                    </tr>
                    <tr>
                      <td>4</td>
                      <td>A. Luck</td>
                      <td>476.3</td>
                      <td>ROMOLICIOUS 9</td>
                    </tr>
                    <tr>
                      <td>5</td>
                      <td>D. Brees</td>
                      <td>368.5</td>
                      <td>Piss Jugs</td>
                    </tr>
                    <tr>
                      <td>6</td>
                      <td>D. Watson</td>
                      <td>361.7</td>
                      <td>Dumpster Fire</td>
                    </tr>
                    <tr>
                      <td>7</td>
                      <td>A. Rodgers</td>
                      <td>361.5</td>
                      <td>Team Cory</td>
                    </tr>
                    <tr>
                      <td>8</td>
                      <td>R. Wilson</td>
                      <td>359.0</td>
                      <td>FART JUGS</td>
                    </tr>
                    <tr>
                      <td>9</td>
                      <td>J. Goff</td>
                      <td>342.4</td>
                      <td>Piss Jugs</td>
                    </tr>
                    <tr>
                      <td>10</td>
                      <td>P. Rivers</td>
                      <td>340.0</td>
                      <td>Team Frank</td>
                    </tr>
                    <tr>
                      <td>11</td>
                      <td>K. Cousins</td>
                      <td>330.6</td>
                      <td>Jeffy Sessions</td>
                    </tr>
                    <tr>
                      <td>12</td>
                      <td>C. Newton</td>
                      <td>330.6</td>
                      <td>Jeffy Sessions</td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="top-score-pos">
                <h3>Running Back</h3>
                <table className="styled">
                  <thead>
                    <tr>
                      <th>Rank</th>
                      <th>Player</th>
                      <th>Total Points</th>
                      <th>Manager</th>
                    </tr>
                  </thead>
          
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>T. Gurley</td>
                      <td>342.6</td>
                      <td>Jeffy Sessions</td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>C. McCaffrey</td>
                      <td>329.5</td>
                      <td>Cookie Boi</td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>S. Barkley</td>
                      <td>318.1</td>
                      <td>RAVENous Pickle</td>
                    </tr>
                    <tr>
                      <td>4</td>
                      <td>A. Kamara</td>
                      <td>313.7</td>
                      <td>Sportsball bois</td>
                    </tr>
                    <tr>
                      <td>5</td>
                      <td>E. Elliott</td>
                      <td>290.6</td>
                      <td>Cookie Boi</td>
                    </tr>
                    <tr>
                      <td>6</td>
                      <td>M. Gordon</td>
                      <td>242.4</td>
                      <td>Dumpster Fire</td>
                    </tr>
                    <tr>
                      <td>7</td>
                      <td>J. Conner</td>
                      <td>241.6</td>
                      <td>ROMOLICIOUS 9</td>
                    </tr>
                    <tr>
                      <td>8</td>
                      <td>J. White</td>
                      <td>218.2</td>
                      <td>Dumpster Fire</td>
                    </tr>
                    <tr>
                      <td>9</td>
                      <td>K. Hunt</td>
                      <td>217.2</td>
                      <td>Team Cory</td>
                    </tr>
                    <tr>
                      <td>10</td>
                      <td>D. Johnson</td>
                      <td>211.3</td>
                      <td>Piss Jugs</td>
                    </tr>
                    <tr>
                      <td>11</td>
                      <td>J. Mixon</td>
                      <td>210.7</td>
                      <td>Piss Jugs</td>
                    </tr>
                    <tr>
                      <td>12</td>
                      <td>P. Lindsay</td>
                      <td>205.3</td>
                      <td>ROMOLICIOUS 9</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div className="cols-2">
              <div className="top-score-pos">
                <h3>Wide Receiver</h3>
                <table className="styled">
                  <thead>
                    <tr>
                      <th>Rank</th>
                      <th>Player</th>
                      <th>Total Points</th>
                      <th>Manager</th>
                    </tr>
                  </thead>
          
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>D. Adams</td>
                      <td>274.1</td>
                      <td>RAVENous Pickle</td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>A. Brown</td>
                      <td>271.7</td>
                      <td>Dumpster Fire</td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>T. Hill</td>
                      <td>264.4</td>
                      <td>FART JUGS</td>
                    </tr>
                    <tr>
                      <td>4</td>
                      <td>D. Hopkins</td>
                      <td>255.3</td>
                      <td>FART JUGS</td>
                    </tr>
                    <tr>
                      <td>5</td>
                      <td>M. Thomas</td>
                      <td>247.6</td>
                      <td>Piss Jugs</td>
                    </tr>
                    <tr>
                      <td>6</td>
                      <td>A. Thielen</td>
                      <td>245.6</td>
                      <td>Cookie Boi</td>
                    </tr>
                    <tr>
                      <td>7</td>
                      <td>J. Jones</td>
                      <td>245.1</td>
                      <td>ROMOLICIOUS 9</td>
                    </tr>
                    <tr>
                      <td>8</td>
                      <td>J. Smith-Schuster</td>
                      <td>229.2</td>
                      <td>Team Cory</td>
                    </tr>
                    <tr>
                      <td>9</td>
                      <td>M. Evans</td>
                      <td>221.8</td>
                      <td>Jeffy Sessions</td>
                    </tr>
                    <tr>
                      <td>10</td>
                      <td>R. Woods</td>
                      <td>219.1</td>
                      <td>Sportsball bois</td>
                    </tr>
                    <tr>
                      <td>11</td>
                      <td>K. Allen</td>
                      <td>203.2</td>
                      <td>FART JUGS</td>
                    </tr>
                    <tr>
                      <td>12</td>
                      <td>S. Diggs</td>
                      <td>200.6</td>
                      <td>Piss Jugs</td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="top-score-pos">
                <h3>Tight End</h3>
                <table className="styled">
                  <thead>
                    <tr>
                      <th>Rank</th>
                      <th>Player</th>
                      <th>Total Points</th>
                      <th>Manager</th>
                    </tr>
                  </thead>
          
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>T. Kelce</td>
                      <td>234.4</td>
                      <td>Cookie Boi</td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>Z. Ertz</td>
                      <td>219.3</td>
                      <td>Piss Jugs</td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>G. Kittle</td>
                      <td>189.3</td>
                      <td>FART JUGS</td>
                    </tr>
                    <tr>
                      <td>4</td>
                      <td>E. Ebron</td>
                      <td>175.2</td>
                      <td>ROMOLICIOUS 9</td>
                    </tr>
                    <tr>
                      <td>5</td>
                      <td>J. Cook</td>
                      <td>155.3</td>
                      <td>Sportsball bois</td>
                    </tr>
                    <tr>
                      <td>6</td>
                      <td>A. Hooper</td>
                      <td>118.3</td>
                      <td>Cookie Boi</td>
                    </tr>
                    <tr>
                      <td>7</td>
                      <td>K. Rudolph</td>
                      <td>115.5</td>
                      <td>Jeffy Sessions</td>
                    </tr>
                    <tr>
                      <td>8</td>
                      <td>T. Burton</td>
                      <td>114.3</td>
                      <td>Dumpster Fire</td>
                    </tr>
                    <tr>
                      <td>9</td>
                      <td>D. Njoku</td>
                      <td>108.2</td>
                      <td>Sportsball bois</td>
                    </tr>
                    <tr>
                      <td>10</td>
                      <td>R. Gronkowski</td>
                      <td>104.3</td>
                      <td>Team Frank</td>
                    </tr>
                    <tr>
                      <td>11</td>
                      <td>O. Howard</td>
                      <td>103.5</td>
                      <td>FART JUGS</td>
                    </tr>
                    <tr>
                      <td>12</td>
                      <td>V. McDonald</td>
                      <td>102.6</td>
                      <td>Dumpster Fire</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div className="cols-2">
              <div className="top-score-pos">
                <h3>Defense / Special Teams</h3>
                <table className="styled">
                  <thead>
                    <tr>
                      <th>Rank</th>
                      <th>Player</th>
                      <th>Total Points</th>
                      <th>Manager</th>
                    </tr>
                  </thead>
          
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>Bears</td>
                      <td>178</td>
                      <td>FART JUGS</td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>Ravens</td>
                      <td>128</td>
                      <td>Team Frank</td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>Vikings</td>
                      <td>125</td>
                      <td>Sportsball bois</td>
                    </tr>
                    <tr>
                      <td>4</td>
                      <td>Texans</td>
                      <td>119</td>
                      <td>RAVENous Pickle</td>
                    </tr>
                    <tr>
                      <td>5</td>
                      <td>Rams</td>
                      <td>116</td>
                      <td>Dumpster Fire</td>
                    </tr>
                    <tr>
                      <td>6</td>
                      <td>Titans</td>
                      <td>101</td>
                      <td>Piss Jugs</td>
                    </tr>
                    <tr>
                      <td>7</td>
                      <td>Jaguars</td>
                      <td>101</td>
                      <td>Team Cory</td>
                    </tr>
                    <tr>
                      <td>8</td>
                      <td>Steelers</td>
                      <td>100</td>
                      <td>Team Cory</td>
                    </tr>
                    <tr>
                      <td>9</td>
                      <td>Saints</td>
                      <td>99</td>
                      <td>Piss Jugs</td>
                    </tr>
                    <tr>
                      <td>10</td>
                      <td>Broncos</td>
                      <td>96</td>
                      <td>Jeffy Sessions</td>
                    </tr>
                    <tr>
                      <td>11</td>
                      <td>Bills</td>
                      <td>94</td>
                      <td>Dumpster Fire</td>
                    </tr>
                    <tr>
                      <td>12</td>
                      <td>Redskins</td>
                      <td>93</td>
                      <td>Team Cory</td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="top-score-pos">
                <h3>Kicker</h3>
                <table className="styled">
                  <thead>
                    <tr>
                      <th>Rank</th>
                      <th>Player</th>
                      <th>Total Points</th>
                      <th>Manager</th>
                    </tr>
                  </thead>
          
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>K. Fairbairn</td>
                      <td>157</td>
                      <td>Free Agent</td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>W. Lutz</td>
                      <td>147</td>
                      <td>Piss Jugs</td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>J. Myers</td>
                      <td>144</td>
                      <td>Free Agent</td>
                    </tr>
                    <tr>
                      <td>4</td>
                      <td>J. Tucker</td>
                      <td>141</td>
                      <td>Team Cory</td>
                    </tr>
                    <tr>
                      <td>5</td>
                      <td>H. Butker</td>
                      <td>139</td>
                      <td>FART JUGS</td>
                    </tr>
                    <tr>
                      <td>6</td>
                      <td>M. Crosby</td>
                      <td>138</td>
                      <td>Cookie Boi</td>
                    </tr>
                    <tr>
                      <td>7</td>
                      <td>R. Gould</td>
                      <td>132</td>
                      <td>Team Frank</td>
                    </tr>
                    <tr>
                      <td>8</td>
                      <td>A. Rosas</td>
                      <td>130</td>
                      <td>Cookie Boi</td>
                    </tr>
                    <tr>
                      <td>9</td>
                      <td>B. Maher</td>
                      <td>128</td>
                      <td>Free Agent</td>
                    </tr>
                    <tr>
                      <td>10</td>
                      <td>S. Gostkowski</td>
                      <td>125</td>
                      <td>Team Cory</td>
                    </tr>
                    <tr>
                      <td>11</td>
                      <td>D. Hopkins</td>
                      <td>118</td>
                      <td>Free Agent</td>
                    </tr>
                    <tr>
                      <td>12</td>
                      <td>S. Janikowski</td>
                      <td>113</td>
                      <td>ROMOLICIOUS 9</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="inner">
            <h2>Tier 1 Player Management</h2>
            <div className="chart-desc">(how many tier 1 players each team had)</div>
            <canvas id="chartTier1"></canvas>
          </div>
        </div>

        <div className="row">
          <div className="inner">
            <h2>Weekly Reward Winners</h2>
            <table className="styled weekly-reward">
              <thead>
                <tr>
                  <th>Week</th>
                  <th>Challenge</th>
                  <th>Player(s) - Total Points</th>
                  <th>Manager</th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td>1</td>
                  <td>Top Scoring Defense</td>
                  <td>Vikings - 18 pts</td>
                  <td>Sportsball bois</td>
                </tr>

                <tr>
                  <td>2</td>
                  <td>Top Scoring TE <span className="sm-label">(single)</span></td>
                  <td>Travis Kelce - 26.4 pts</td>
                  <td>Cookie Boi</td>
                </tr>

                <tr>
                  <td>3</td>
                  <td>Top Scoring QB</td>
                  <td>Matt Ryan - 50.2 pts</td>
                  <td>RAVENous Pickle</td>
                </tr>

                <tr>
                  <td>4</td>
                  <td>Top Scoring WR <span className="sm-label">(single)</span></td>
                  <td>Cooper Kupp - 32.7 pts</td>
                  <td>Dumpster Fire</td>
                </tr>

                <tr>
                  <td>5</td>
                  <td>Top Scoring RB <span className="sm-label">(single)</span></td>
                  <td>James Connor - 32.5 pts</td>
                  <td>ROMOLICIOUS 9</td>
                </tr>

                <tr>
                  <td>6</td>
                  <td>Top Scoring WR Corps <span className="sm-label">(does not include Flex)</span></td>
                  <td>D. Hopkins & T. Hill - 50.5 pts</td>
                  <td>FART JUGS</td>
                </tr>

                <tr>
                  <td>7</td>
                  <td>Top Scoring RB Corps <span className="sm-label">(does not include Flex)</span></td>
                  <td>K. Hunt & T. Coleman - 49.8 pts</td>
                  <td>Team Cory</td>
                </tr>

                <tr>
                  <td>8</td>
                  <td>Top Scoring QB</td>
                  <td>D. Watson - 41 pts</td>
                  <td>Dumpster Fire</td>
                </tr>

                <tr>
                  <td>9</td>
                  <td>Top Scoring WR Corps <span className="sm-label">(does not include Flex)</span></td>
                  <td>M. Thomas & J. Edelman - 47.5 pts</td>
                  <td>ROMOLICIOUS 9</td>
                </tr>

                <tr>
                  <td>10</td>
                  <td>Top Scoring RB Corps <span className="sm-label">(does not include Flex)</span></td>
                  <td>A. Kamara & M. Breida - 50.9 pts</td>
                  <td>Sportsball bois</td>
                </tr>

                <tr>
                  <td>11</td>
                  <td>Top Scoring TE <span className="sm-label">(single)</span></td>
                  <td>Travis Kelce - 23.7 pts</td>
                  <td>Cookie Boi</td>
                </tr>

                <tr>
                  <td>12</td>
                  <td>Top Scoring Kicker</td>
                  <td>S. Janikowski - 12 pts</td>
                  <td>ROMOLICIOUS 9</td>
                </tr>

                <tr>
                  <td>13</td>
                  <td>Lowest Team Score</td>
                  <td>66.2 pts</td>
                  <td>Team Frank</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </main>

    </React.Fragment>
  )
}

export default Page;